<template>
  <div>
    <div class='flex flex-row justify-start gap-x-4 items-center'>
      <h4 
        class='category text-sm font-medium' 
        :style='textColor'>
        #{{session.category}}
      </h4>
      <span v-if='showWatchedIndicator'
        class='p-1 bg-gray-50 text-sm font-medium opacity-90'
        :class='watchStatusBasedCss'>
        {{ watchedStatusTagTitle(session.id) }}
      </span>
    </div>
    <div class='mt-2 text-xl lg:text-3xl font-bold text-gray-900'>
      {{session.title}}
    </div>
    <div class='mt-4 text-base leading-6 font-normal whitespace-pre-line text-gray-800' style='word-break: keep-all;'>
      {{session.description}}
      <a v-if='hasDetailedLink'
        :href='detailedLink'
        class='underline font-semibold hover:no-underline'
        target='_blank'
        :style='textColor'>
        발표자료
      </a>
    </div>
    <button v-if='showDownloadFileButton'
      class='mt-4 py-3 px-6 lg:px-6 uppercase bg-gray-700 rounded-md hover:shadow-md text-white font-semibold text-xs'
      :style='`background-color: var(--darkColor)`' 
      @click='downloadPdf'>
      {{session.configurations.sessionPdfDownloadName}}
    </button>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)

export default {
  name: 'SessionDescription',
  components: {
  },
  props: [
    'session',
    'sessionColor',
  ],
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('watchedVideos', [
      'watchedStatusTagTitle'
    ]),
    hasDetailedLink () {
      return this.session.configurations && 
             this.detailedLink
    },
    detailedLink () {
      return this.session.configurations.detailedLink
    },
    showDownloadFileButton () {
      return this.hasValidToken && this.sameOrAfterVideoScheduledStartDate && this.session.configurations.sessionPdfDownloadUrl
    },
    sameOrAfterVideoScheduledStartDate () {
      return dayjs().tz('Asia/Seoul').isSameOrAfter(dayjs(this.session.scheduledStartTime).tz('Asia/Seoul'), 'date')
    },
    sameVideoScheduledStartDate () {
      return dayjs().tz('Asia/Seoul').isSame(dayjs(this.session.scheduledStartTime).tz('Asia/Seoul'), 'date')
    },
    watchStatusBasedCss () {
      return this.watchedStatusTagTitle(this.session.id) === '시청완료' ? 'bg-green-50 text-green-800 border border-green-300' : 'bg-gray-800 text-gray-100'
    },
    showWatchedIndicator () {
      return this.sameVideoScheduledStartDate && this.watchedStatusTagTitle(this.session.id)
    },
    textColor () {
      return `color: #${this.sessionColor}`
    },
  },
  methods: {
    ...mapActions('sessions', [
      'postLikeSession',
    ]),
    likeSession () {
      this.postLikeSession(this.session.id)
    },
    downloadPdf () {
      window.open(this.session.configurations.sessionPdfDownloadUrl, '_blank')
    },
  }
}
</script>
