<template>
  <div>
    <welcome-conference-banner v-if='showConferenceBanner' class='mb-8'/>
    <div class='mt-2 lg:mt-12'>
      <div class='flex flex-row justify-end mr-4 lg:mr-0'>
        <router-link
          :to='{ name: "Notices" }'
          class='py-2 lg:py-3 px-6 lg:px-12 uppercase rounded-md hover:shadow-md text-white font-semibold'
          :style='`background-color: var(--darkColor)`' >
          시청방법안내
        </router-link>
      </div>
      <div v-for='category in selectableCategories'
        :key='`category-${category}`'
        class='mb-16'>
        <h1 class='text-xl lg:text-3xl font-bold uppercase mb-2 lg:mb-4 ml-4 lg:ml-0 py-4'>
          {{category}}
        </h1>
        <div class='flex flex-row flex-wrap gap-1'>
          <session-card v-for='session in filteredSessionsByCategory(category)' 
            :key='`session-card-${session.id}`'
            :session='session'
            class='session-card'
            @show-session-details='showSessionDetails(session)' />
        </div>
      </div>
    </div>
    <welcome-sponsor-carousel v-if='showSponsorCarousel' class='mt-8' />
    <modal
      name='session-details-modal'
      :adaptive='true'
      :click-to-close='false'
      height='95%'
      width='100%'
      :max-width='880'
      @before-open='beforeOpenModal'
      @closed='closedModal'
      classes='session-details-modal relative'>
      <button class='z-30 absolute top-3 right-3' @click="$modal.hide('session-details-modal')">
        <x-icon class='text-white bg-gray-800 lg:text-black lg:bg-white rounded' />
      </button>
      <session-modal-content
        :session='sessionDetail'
        class='h-full' />
    </modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { XIcon } from '@vue-hero-icons/outline'
import SessionCard         from '@/components/SessionCard.vue'
import SessionModalContent from '@/components/cmccme2023/SessionModalContent.vue'

export default {
  name: 'Programs',
  components: {
    XIcon,
    SessionCard,
    SessionModalContent,
    'welcome-conference-banner': () => import('@/components/welcome/WelcomeConferenceBanner.vue'),
    'welcome-sponsor-carousel': () => import('@/components/welcome/WelcomeSponsorsCarousel.vue'),
  },
  props: {
    defaultToToday: {
      type: Boolean,
      default: false,
    },
    needToBeLoggedIn: {
      type: Boolean,
      default: false,
    },
    showConferenceBanner: {
      type: Boolean,
      default: true,
    },
    showSponsorCarousel: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      sessionDetail: null,
    }
  },
  watch: {
    '$route.query.session_id': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          let session = this.sessions.find(session => session.id === parseInt(newVal))
          if (session) {
            this.showSessionDetails(session)
          } else {
            let query = Object.assign({}, this.$route.query)
            delete query.session_id;
            this.$router.replace({ query })
          }
        } else {
          this.$modal.hide('session-details-modal')
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapState('sessions', [
      'sessions',
    ]),
    ...mapGetters('filters', [
      'showingContentsFilterListByFilterName',
    ]),
    selectableCategories () {
      return this.showingContentsFilterListByFilterName('programs', 'category')
    },
  },
  methods: {
    ...mapActions([
      'hideFullpageLoadingIndicator',
      'showFullpageLoadingIndicator',
    ]),
    ...mapActions('filters', [
      'getFilters',
    ]),
    ...mapActions('sessions', [
      'getSessionsWithSpeakers',
    ]),
    ...mapActions('users', [
      'checkTokenStatus',
    ]),
    ...mapActions('watchedVideos', [
      'getMyWatchedVideoSummaries',
    ]),
    ...mapActions('users', [
      'getRegistrationData',
    ]),
    showSessionDetails (session) {
      this.sessionDetail = session
      this.$modal.show('session-details-modal')
    },
    filteredSessionsByCategory (category) {
      return this.sessions.filter(session => {
        return session.category === category
      })
    },
    beforeOpenModal () {
      let body = document.body
      let scrollY = window.scrollY
      body.style.position = 'fixed';
      body.style.top = `-${scrollY}px`;
      body.style.left = '0px'
      body.style.right = '0px'
    },
    closedModal () {
      let body = document.body
      let scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      body.style.left = ''
      body.style.right = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken && this.needToBeLoggedIn) {
        this.$router.replace({name: 'Login', query: {redirect_route: 'Programs'} })
      }
    })
  },
  mounted () {
    this.showFullpageLoadingIndicator()
    this.getRegistrationData()
    this.getMyWatchedVideoSummaries()
    this.getSessionsWithSpeakers().then(() => {
      this.hideFullpageLoadingIndicator()
    })
    this.getFilters()
  },
  beforeDestroy () {
    let body = document.body
    body.style.position = '';
    body.style.top = '';
    body.style.left = ''
    body.style.right = ''
  }
}
</script>

<style>
.session-card {
  @apply m-4 h-72;
  width: 100%;
}

@media (min-width: 1024px) { 
  .session-card {
    @apply m-0 h-80;
    width: calc(25% - 4px);
  }

}
</style>
