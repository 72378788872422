<template>
  <div>
    <div class='bg-black relative'>
      <div v-if='showVideo' class='px-0 lg:px-16'>
        <vod-content-vimeo
          :key='`video-for-session-${session.id}`'
          :session='sessionFromApi'
          :showing-content='showingVideoContent'
          :autoplay='false'
          :default-volume='0.8'
          :mute='false' 
          :loop='false'
          @end-vod='endVod'
          class='z-0' />
      </div>
      <div v-else-if='!this.sameOrAfterVideoScheduledStartDate'
        class='flex flex-row justify-center items-center text-center flex-grow h-96'>
        <h1 class='w-full h-full text-center flex flex-col justify-center items-center absolute top-0 left-0 font-bold text-white text-2xl lg:text-4xl z-20'>
          {{sessionExpectedStartDateString}} 오픈
        </h1>
      </div>
      <div v-else-if='!isVideoViewablePeriod'
        class='flex flex-col justify-center items-center text-center flex-grow h-96 uppercase text-gray-200 hover:text-white cursor-pointer hover:font-bold'>
        <div>등록하신 재시청기간이 종료되었습니다.</div>
      </div>
      <div v-else
        class='flex flex-col justify-center items-center text-center flex-grow h-96 uppercase text-gray-200 hover:text-white cursor-pointer hover:font-bold'
        @click='goToLogin'>
        <div>등록하신 분들만 시청 가능합니다.</div>
        <div>등록하신 분들은 <span class='ml-1 underline'>로그인</span> 부탁 드립니다.</div>
      </div>
      <div v-if='showRatingOverlay'
        class='opacity-90 absolute bg-gray-900 w-full h-full top-0 left-0'>
        <div class='flex flex-col justify-center items-center h-full text-white gap-y-2'>
          <div class='text-lg font-semibold'>시청이 완료되었습니다.</div>
          <div>별모양을 클릭하시어 시청하신 강의의 만족도를 남겨 주십시오. (1점~5점)</div>
          <star-rating 
            v-model='myScore' 
            :star-size='24' 
            :animate='true' 
            :padding='1' 
            :glow='1' 
            @rating-selected='setScore' />
        </div>
      </div>
    </div>
    <div class='overflow-auto pb-48 main-session-modal-content-height'>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import VodContentVimeo from '@/components/contents/VodContentVimeo.vue'
import DateTimeStringHelpers from '@/utils/date-time-string-helpers'
import StarRating from 'vue-star-rating'
import jsonLogic  from 'json-logic-js'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)

export default {
  name: 'ModalVideoLayout',
  components: {
    VodContentVimeo,
    StarRating,
  },
  props: [
    'session',
  ],
  data () {
    return {
      sessionFromApi: {},
      showRatingOverlay: false,
      myScore: 0,
    }
  },
  watch: {
    'session.id': {
      handler (newVal, oldVal) {
        if (newVal !== oldVal) {
          if (this.$route.query.session_id !== newVal.toString()) {
            this.$router.push({query: {session_id: newVal}})
          }
          if (this.hasValidToken) {
              this.getSingleSessionV2(newVal).then(resp => {
              this.sessionFromApi = resp
            })
          } 
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('users', [
      'hasValidToken',
      'registrationData',
    ]),
    ...mapState('watchedVideos', [
      'playStartTime',
      'lastPlayed',
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'isCmccmeEvent',
    ]),
    showVideo () {
      return this.hasValidToken && this.sameOrAfterVideoScheduledStartDate && this.isVideoViewablePeriod
    },
    sameOrAfterVideoScheduledStartDate () {
      return dayjs().tz('Asia/Seoul').isSameOrAfter(dayjs(this.session.scheduledStartTime).tz('Asia/Seoul'), 'date')
    },
    isVideoViewablePeriod () {
      let condition = this.sessionFromApi.configurations && this.sessionFromApi.configurations.showCondition ? this.sessionFromApi.configurations.showCondition : true
      return jsonLogic.apply(condition, {currentDateTime: this.currentDateTimeString, registartionType: this.registrationData.registrationType})
    },
    currentDateTimeString () {
      return dayjs().tz('Asia/Seoul').format('YYYYMMDDHHmm')
    },
    sessionExpectedStartDateString () {
      let dateString = dayjs(this.session.scheduledStartTime).tz('Asia/Seoul').format('MM월 DD일')
      let dayOfWeek  = DateTimeStringHelpers.koreanDayOfWeek(this.session.scheduledStartTime)
      return `${dateString} (${dayOfWeek})`
    },
    showingVideoContent () {
      return this.sessionFromApi.listOfContents ? this.sessionFromApi.listOfContents[0] : {}
    },
  },
  methods: {
    ...mapActions('sessions', [
      'getSingleSessionV2',
      'setSessionScore',
    ]),
    ...mapActions('watchedVideos', [
      'trackWatchedVideo',
      'resetPlayStartTime',
      'resetLastPlayed',
    ]),
    goToLogin () {
      this.$router.push({name: 'Login'})
    },
    setScore (score) {
      this.setSessionScore({sessionId: this.session.id, score: score}).then(() => {
        this.showRatingOverlay = false
      })
    },
    endVod () {
      this.showRatingOverlay = true
    },
    sameVideoScheduledStartDate () {
      return dayjs().tz('Asia/Seoul').isSame(dayjs(this.session.scheduledStartTime).tz('Asia/Seoul'), 'date')
    },
  },
  beforeDestroy () {
    if (this.$route.query.session_id) {
      let query = Object.assign({}, this.$route.query)
      delete query.session_id;
      this.$router.replace({ query })
    }
    if (this.isCmccmeEvent && this.playStartTime && this.sameVideoScheduledStartDate()) {
      let params = {
        event_id: this.showingEventId,
        session_id: this.session.id,
        content_id: this.showingVideoContent.id,
        video_player: 'vimeo',
        start_time: this.playStartTime,
        watched_status: '',
        played_times: [[0, this.lastPlayed]],
      }
      this.trackWatchedVideo(params).then(() => {
        this.resetPlayStartTime()
        this.resetLastPlayed()
      })
    }
  },
}
</script>

<style lang='scss'>

.main-session-modal-content-height {
  height: calc(100% - 200px);
}

@media (min-width: 700px) { 
  .main-session-modal-content-height {
    height: calc(100% - 384px);
  }
}
</style>

